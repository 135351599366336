<template>
  <div>
    <Modal v-model="addCommonProductModal" class-name="addCommonProductStyle" :title="title" :width="modalWidth"
      :transfer="false" :mask-closable="false" @on-visible-change="addCommonProductChange" :footer-hide="!showOptionsBtn">
      <div class="details_box_styles">
        <div class="commodity_box">
          <!--树状分类模块-->
          <template v-if="showTree">
            <commonTree ref="treeDom" @selectCategory="selectCategory" :customStyle="customStyle"></commonTree>
          </template>
          <!--商品列表-->
          <Card :class="showTree ? 'content_box' : 'content_content'" dis-hover :bordered="false">
            <!--已选择商品（可以改变商品数量的）-->
            <div class="basic_information_box" v-if="matchingGoodsType === 'add'">
              <Card dis-hover>
                <div class="options_box">
                  <h3 class="select_title">{{ $t('key1000851') }}</h3>
                  <div class="btns_box">
                    <Button type="primary" size="small" class="mr15" @click="queryProductBtn">{{ $t('key1000463') }}</Button>
                    <Button size="small" @click="reset">{{ $t('key1000622') }}</Button>
                  </div>
                </div>
                <div class="tag_box_conter">
                  <Tag
                    class="tag_item_style"
                    :class="system === 'distributor' ? 'tag_bg_distributor': 'tag_bg_default'"
                    type="border"
                    color="#fff"
                    v-for="(item,index) in selectCommodityList"
                    :key="index"
                    @on-close="delCommodity(item, index)"
                    closable>
                    <div class="text">{{ item.ymsSku }}</div>
                    <div class="txt">*</div>
                    <InputNumber :min="1" :max="99999" :formatter="value => `${parseInt(value)}`"
                      v-model.trim="item.quantity" style="width:75px;"></InputNumber>
                  </Tag>
                </div>
              </Card>
            </div>
            <!--搜索字符-->
            <Form v-if="!ymsNoTable" ref="pageParams" :model="pageParams" @submit.native.prevent class="mb20">
              <Row type="flex" :gutter="gutterItem">
                <!--运营系统的筛选项-->
                <template v-if="systemType === 'yms'">
                  <Col span="10">
                    <dropDownFilter
                      ref="dropDownTextareaFilter"
                      inputType="textarea"
                      :selectStyle="{width: '100px'}"
                      :dropDownInputList="dropDownTextareaList"
                      :defaultSelectValue="defaultTextareaValue"
                      @changeInput="changeTextareaInput"
                      @updateInputValue="updateTextareaValue">
                    </dropDownFilter>
                  </Col>
                  <Col span="9">
                    <dropDownFilter
                      ref="dropDownFilter"
                      :selectStyle="{width: '110px'}"
                      :dropDownInputList="dropDownInputList"
                      :defaultSelectValue="defaultSelectValue"
                      @changeInput="changeInput"
                      @updateInputValue="updateInputValue">
                    </dropDownFilter>
                  </Col>
                </template>
                <!--供应商系统的筛选项-->
                <template v-if="systemType === 'supplier'">
                  <Col span="12">
                    <dropDownFilter
                      ref="dropDownTextareaFilter"
                      inputType="textarea"
                      :selectStyle="{width: '100px'}"
                      :dropDownInputList="dropDownTextareaList"
                      :defaultSelectValue="defaultTextareaValue"
                      @changeInput="changeTextareaInput"
                      @updateInputValue="updateTextareaValue">
                    </dropDownFilter>
                  </Col>
                </template>
                <!--分销商系统的筛选项-->
                <template v-if="systemType === 'distributor'">
                  <Col span="9">
                    <dropDownFilter
                      :inputType="inputTypeValue"
                      ref="dropDownFilter"
                      :selectStyle="{width: '100px'}"
                      :dropDownInputList="dropDownInputList"
                      :defaultSelectValue="defaultSelectValue"
                      @changeInput="changeInput"
                      @updateInputValue="updateInputValue">
                    </dropDownFilter>
                  </Col>
                  <Col :span="languagesVal === 'CN' ? '10' : '12'">
                    <Form-item :label="$t('key1000847')" :labelWidth="languagesVal === 'CN' ? 90 : 110">
                      <local-tabButton :tabButtonList="subscriptionList" @selectStatus="subscriptionBtn"></local-tabButton>
                    </Form-item>
                  </Col>
                </template>
                <Col style="width: 170px;" push="1">
                  <Button @click="reset" class="mr12">{{ $t('key1000095') }}</Button>
                  <Button type="primary" @click="search">{{ $t('key1000274') }}</Button>
                </Col>
              </Row>
            </Form>
            <!--展示已经选中的商品-->
            <template v-if="showSelectSku">
              <div class="flex mb20" v-if="selectTableList.length>0">
                <h3 class="select_tag_title">{{ $t('key1000851') }}</h3>
                <Row type="flex" :gutter="gutterItem" class="tag_box_styles">
                  <div class="tag_box_item" v-for="(item, index) in selectTableList" :key="index">
                    <Tag closable color="blue" :size="showQuantity ? 'large' : 'small'" @on-close="deleteTag(item, index)">
                      {{ item.sku }}
                      <InputNumber
                        v-if="showQuantity"
                        :min="1"
                        size="small"
                        :max="99999"
                        :formatter="value => `${parseInt(value)}`"
                        v-model.trim="item.quantity"
                        class="ipt">
                      </InputNumber>
                    </Tag>
                    <Radio
                      v-if="showMainMaterials"
                      :true-value="1"
                      :false-value="0"
                      v-model="item.isMaster"
                      @on-change="(val)=>setAsMainMaterialChange(val, index)">
                      {{ $t('key1000852') }}
                    </Radio>
                  </div>
                </Row>
              </div>
            </template>
            <!--列表区域-->
            <div class="table-box" v-if="!ymsNoTable">
              <Table
                ref="selection"
                highlight-row
                border
                max-height="485"
                :loading="tableLoading"
                :columns="tableColumns"
                @on-select-cancel="selectCancel"
                @on-select-all-cancel="selectAllCancel"
                @on-selection-change="tableChange"
                :data="tableData">
              </Table>
              <!--分页区域-->
              <div class="flex align-items-center mt20" :class="!matchingGoodsType ? 'justify-content-between' : 'justify-content-end'">
                <div v-if="!matchingGoodsType">{{ $t('key1000293') + selectTableData.length + $t('key1000850') }}</div>
                <Page
                  v-if="!tableLoading"
                  :total="total"
                  @on-change="changePage"
                  show-total
                  :page-size="pageParams.pageSize"
                  show-elevator
                  :current="pageParams.pageNum"
                  show-sizer
                  @on-page-size-change="changePageSize"
                  placement="top"
                  :page-size-opts="pageArray">
                </Page>
              </div>
            </div>
          </Card>
        </div>
      </div>
      <template #footer v-if="showOptionsBtn">
        <Button @click="addCommonProductModal = false">{{ $t('key1000098') }}</Button>
        <Button type="primary" :disabled="selectTableData.length <=0" @click="addCommonProductBtn">{{ $t('key1000097') }}</Button>
      </template>
    </Modal>
    <!--选择确认弹窗-->
    <Modal v-model="selectConfirmationModal" :title="$t('key1000848')" width="500" :transfer="false"
      :mask-closable="false" @on-visible-change="selectConfirmationChange">
      <h2 class="mb12">{{ $t('key1000853') }}</h2>
      <div class="mb10 flex align-items-center">
        <Checkbox v-model="isSure" :true-value="1" :false-value="0">{{ $t('key1000854') }}</Checkbox>
        <Tooltip max-width="500" placement="top"
          :content="$t('key1000849')">
          <Icon class="cursor" color="#FD5425" size="18" type="md-alert"/>
        </Tooltip>
      </div>
      <template #footer>
        <Button class="mr15" @click="selectConfirmationModal = false">{{ $t('key1000098') }}</Button>
        <Button class="mr15" type="primary" @click="selectConfirmationBtn">{{ $t('key1000444') }}</Button>
      </template>
    </Modal>
  </div>
</template>

<script>
import { aliasb039bd6e4486415fba0a6b42f8cd95d1 } from '@/customFolder/customVueAlias.js';

import Mixin from "@/components/mixin/common_mixin";
import dropDownFilter from "@/components/common/dropDownFilter";
import commonTree from "@/components/common/commonTree";
import api from '@/api/';
import {setConfigCharacters} from "@/utils/common";

export default {
  name: "addCommonProductModal",
  mixins: [Mixin],
  props: {
    // 列表请求接口
    apiUrl: {
      type: String,
      default: ''
    },
    // 是否展示侧边栏分类筛选
    showTree: {
      type: Boolean,
      default: true
    },
    // 是否自定义侧边栏分类的样式
    customStyle: {
      type: Boolean,
      default: false
    },
    // 标识（用于区分调用不同的查询接口传参）yms、supplier、distributor
    systemType: {
      type: String,
      default: ''
    },
    // 是否显示底部的操作按钮
    showOptionsBtn: {
      type: Boolean,
      default: true
    },
    // 弹窗的宽度
    modalWidth: {
      type: String,
      default: '1200'
    },
    //是否展示已经选中的sku
    showSelectSku: {
      type: Boolean,
      default: false
    },
    // 分销商系统需要展示的字段Key
    distributorShowFieldKeyList: {
      type: Array,
      default: () => {
        return ['options', 'goodsInfo', 'weight', 'length', 'status']
      }
    },
    // 供应商系统需要展示的字段Key
    supplierShowFieldKeyList: {
      type: Array,
      default: () => {
        return ['selection', 'numberNo', 'goodsInfo', 'cnName', 'availableNumber', 'totalSalesVolume']
      }
    },
    // 标题
    title: {
      type: String,
      default: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000858')
    },
    // 展示数量  后面合并下下面这个几个
    showQuantity: {
      type: Boolean,
      default: false
    },
    // 展示主料
    showMainMaterials: {
      type: Boolean,
      default: false
    },
    // 无列表
    ymsNoTable: {
      type: Boolean,
      default: false
    },
    // 是否过滤掉已经组装的商品
    productTypes: {
      type: Array,
      default: () => {
        return []
      }
    },
    // 是否重置商品信息字段
    goodsInfoKey: {
      type: String,
      default: null
    },
    // 平台订单详情数据
    platformOrderDetails: {
      type: Object,
      default: () => {
      }
    }
  },
  data() {
    let v = this;
    return {
      pageParamsStatus: false,
      addCommonProductModal: false,
      pageParams: {},
      tableColumns: [],
      tableData: [],
      total: 0,
      selectTableData: [],
      defaultTextareaValue: 1,
      defaultSelectValue: 1,
      ymsProductIdList: [],
      inputTypeValue: 'textarea',
      subscriptionList: [
        {
          selected: true,
          value: 2,
          title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000096')
        },
        {
          selected: false,
          value: 1,
          title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000859')
        },
        {
          selected: false,
          value: 0,
          title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000860')
        }
      ],
      matchingGoodsType: null, // 匹配商品的类型： add 自动添加匹配商品， manual 手工匹配商品  single 单选商品
      ymsPlatformOrderTransactionId: null,
      selectCommodityList: [], // 已选中商品 (匹配商品的形式的选择商品)
      packageCode: null,
      selectConfirmationModal: false,
      isSure: 0,
      ymsProductGoodsId: null,
      selectConfirmationObj: {},
      platformItemSku: null
    }
  },
  computed: {
    dropDownInputList() {
      return this.handleFilterItems();
    },
    dropDownTextareaList() {
      return this.handleDropDownTextareaItems();
    },
    // 获取当前登录用户的信息
    userInfo() {
      if (this.$store.state.erpConfig) {
        return this.$store.state.erpConfig.userInfo;
      } else {
        return '';
      }
    },
    selectTableList() {
      if (this.selectTableData.length > 0) {
        let talg = this.selectTableData.every((item) => {
          return item.isMaster !== 1;
        });
        if (talg) {
          this.selectTableData[0].isMaster = 1;
        }
      }
      return this.selectTableData;
    }
  },
  methods: {
    // 处理下拉框筛选项
    handleDropDownTextareaItems() {
      let v = this;
      let list = [];
      switch (v.systemType) {
        case 'yms':
          list = [
            {title: `${setConfigCharacters}SPU`, value: 1, placeholder: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000292')}${setConfigCharacters}${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000855')}`, parameterKey: 'ymsSpus'},
            {title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000861'), value: 2, placeholder: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000862'), parameterKey: 'supplierSpus'},
          ];
          break;
        case 'supplier':
          list = [
            {title: 'SPU', value: 1, placeholder: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000863'), parameterKey: 'spus'},
            {title: 'SKU', value: 2, placeholder: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000864'), parameterKey: 'skus'},
            {title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000313'), value: 3, placeholder: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000865'), parameterKey: 'names'},
          ];
          break;
      }
      return list;
    },
    // 处理筛选项
    handleFilterItems() {
      let v = this;
      let list = [];
      if (v.systemType === 'yms') {
        list = [
          {title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000866'), value: 1, parameterKey: 'searchValue'},
          {title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000867'), value: 2, parameterKey: 'searchValue'},
        ]
      } else if (v.systemType === 'distributor') {
        list = [
          {title: `${setConfigCharacters}SKU`, value: 1, placeholder: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000292')}${setConfigCharacters}${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000856')}`, parameterKey: 'ymsSkus'},
          {title: `${setConfigCharacters}SPU`, value: 2, placeholder: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000292')}${setConfigCharacters}${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000855')}`, parameterKey: 'ymsSpus'},
          {title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000867'), value: 3, placeholder: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000868'), parameterKey: 'searchValue'},
        ]
      }
      return list;
    },
    // 处理商品筛选的默认参数
    handlePageParams() {
      let v = this;
      let defaultParams = {
        yms: {
          pageNum: 1,
          pageSize: 15,
          searchValue: '',
          ymsSpus: '',
          supplierSpus: '',
          ymsProductCategoryIdList: [],
          ymsProductIds: [],
          type: 0, // 搜索类型 0 全部搜索 1指定商品
        },
        supplier: {
          pageSize: 15,
          pageNum: 1,
          names: '',
          skus: '',
          spus: '',
          searchValue: '',
          warehouseId: this.getWarehouseId(),
          productTypes: v.productTypes || []
        },
        distributor: {
          distributorMerchantId: null,
          ymsProductCategoryIdList: [],
          ymsSkus: "",
          ymsSpus: "",
          subscriptionStatus: 2, // 收藏状态0: 未收藏，1:已收藏，2:全部
          pageSize: 15,
          pageNum: 1,
          searchValue: ''
        }
      };
      v.pageParams = defaultParams[v.systemType];
    },
    // 处理列表的默认字段
    handleColumns() {
      let v = this;
      let defaultColumns = {
        yms: [
          {
            type: 'selection',
            width: 70,
            fixed: 'left',
            align: 'center'
          },
          {
            title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000316'),
            key: 'commodityInfo',
            align: 'center',
            minWidth: 300,
            render(h, params) {
              let name = params.row.name;
              let list = [
                v.tableImg(h, params, 'primaryImage'),
                h('div', {
                  class: 'text-align-left pl10'
                }, [
                  h('Alink', {
                    props: {
                      linkName: v.handleMultilingualFields(name),
                      textAlign: 'left',
                      className: 'mb5'
                    },
                    on: {
                      AlinkBtn: () => {
                        v.toShopDetails(params.row.ymsProductId);
                      }
                    }
                  }),
                  h('p', {class: 'flex align-items-center'}, `${setConfigCharacters}SPU：` + params.row.ymsSpu),
                  h('p', {class: 'flex align-items-center'}, aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000493') + params.row.supplierSpu),
                ])
              ];
              return h('div', {
                class: 'flex ptb10',
              }, list)
            }
          },
          {
            title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000866'),
            key: 'supplierMerchantId',
            minWidth: 130,
            align: 'center'
          },
          {
            title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000869'),
            key: 'supplierMerchantIdName',
            align: 'center',
            minWidth: 110
          },
          {
            title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000500'),
            key: 'supplyPrice',
            minWidth: 140,
            align: 'center',
            render(h, params) {
              let maxSuggestPrice = params.row.maxSuggestPrice;
              let minSuggestPrice = params.row.minSuggestPrice;
              let price = '';
              if (maxSuggestPrice && minSuggestPrice) {
                price = minSuggestPrice + ' ~ ' + maxSuggestPrice;
              } else {
                price = '0 ~ 0';
              }
              if (maxSuggestPrice && !minSuggestPrice) {
                price = maxSuggestPrice;
              }
              if (!maxSuggestPrice && minSuggestPrice) {
                price = minSuggestPrice;
              }
              return h('div', [
                h('p', price),
                h('p', '(' + v.currencyState + ')')
              ]);
            }
          },
          {
            title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000857'),
            key: 'ymsAvailableNumber',
            minWidth: 110,
            align: 'center',
            render(h, params) {
              let number = params.row.ymsAvailableNumber || 0;
              return h('span', number)
            }
          },
          {
            title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000870'),
            key: 'supplierAvailableNumber',
            minWidth: 110,
            align: 'center',
            render(h, params) {
              let number = params.row.supplierAvailableNumber || 0;
              return h('span', number)
            }
          }
        ],
        supplier: [
          {
            type: 'selection',
            width: 70,
            key: 'selection',
            align: 'center'
          },
          {
            type: 'index',
            title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000275'),
            key: 'numberNo',
            width: 70,
            align: 'left'
          },
          {
            title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000316'),
            minWidth: 320,
            key: 'goodsInfo',
            align: 'left',
            render: (h, params) => {
              let imagePath = params.row.imagePath;
              let supplierSpu = params.row.spu;
              let supplierSku = params.row.sku;
              let name = params.row.name;
              let productGoodsSpecifications = params.row.productGoodsSpecifications || [];
              let spec = productGoodsSpecifications.map(i => i.value).join('、') || '';
              let list = [
                v.tableImg(h, params, null, imagePath),
                h('div', {class: 'flex flex-direction ml10'}, [
                  h('div', [
                    h('Alink', {
                      props: {
                        linkName: name,
                        textAlign: 'left',
                        AlinkClass: 'ellipsis_2'
                      },
                      on: {
                        AlinkBtn: () => {
                          const {href} = v.$router.resolve({
                            path: '/productDetails',
                            query: {
                              type: 'look',
                              productId: params.row.productId
                            }
                          });
                          window.open(href, '_blank');
                        }
                      }
                    }),
                  ]),
                  v.goodsInfoKey === 'reset' ? [
                    h('p', {class: 'mt5'}, aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000871') + supplierSpu),
                    h('p', {class: 'mt5'}, aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000872') + supplierSku)
                  ] : [
                    h('p', {class: 'mt5'}, 'SPU：' + supplierSpu),
                    h('p', {class: 'mt5'}, 'SKU：' + supplierSku),
                    h('p', {class: 'mt5'}, [
                      h('span', aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000207')),
                      h('span', {class: 'color-green'}, spec)
                    ])
                  ]
                ])
              ];
              return h('div', {class: 'flex ptb10'}, list);
            }
          },
          {
            title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000193'),
            key: 'cnName',
            align: 'left',
            minWidth: 160
          },
          {
            title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000873'),
            key: 'availableNumber',
            align: 'left',
            width: 150,
            render: (h, params) => {
              return h('span', v.priceTransform(params.row.availableNumber));
            }
          },
          {
            title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000874'),
            key: 'totalSalesVolume',
            align: 'left',
            minWidth: 150,
            render: (h, params) => {
              return h('span', v.priceTransform(params.row.totalSalesVolume));
            }
          }
        ],
        distributor: [
          {
            type: 'selection',
            width: 70,
            key: 'selection',
            align: 'center'
          },
          {
            title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000283'),
            width: 110,
            fixed: 'left',
            align: 'center',
            key: 'options',
            render: (h, params) => {
              return h('optsButtonList', {
                props: {
                  operationList: [
                    {
                      text: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000875'),
                      show: true,
                      clickFn: () => {
                        // 添加匹配商品
                        if (v.matchingGoodsType === 'add') {
                          v.selectCommodityBtn(params.row);
                        }
                        // 手工匹配商品
                        else if (v.matchingGoodsType === 'manual') {
                          v.selectConfirmationModal = true;
                          v.ymsProductGoodsId = params.row.ymsProductGoodsId;
                          v.selectConfirmationObj = params.row;
                          // 单选
                        } else if (v.matchingGoodsType === 'single') {
                          v.$emit('selectGoodsList', params.row);
                          v.addCommonProductModal = false;
                        }
                      }
                    }
                  ]
                },
                on: {
                  setWidth(value) {
                    if (value) {
                      v.optionBtnWidth.push(value);
                      params.column.width = Math.max(...v.optionBtnWidth);
                      v.$forceUpdate();
                    }
                  }
                }
              });
            }
          },
          {
            title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000316'),
            minWidth: 320,
            key: 'goodsInfo',
            align: 'left',
            render: (h, params) => {
              if (params.row._productInfo) {
                let skuImgPath = params.row._productInfo.primaryImage;
                let spuImgPath = params.row._productInfo._spuInfo.primaryImage
                let imgPath = skuImgPath ? skuImgPath : spuImgPath;
                let productGoodsSpecifications = params.row._productInfo.productGoodsSpecificationList || [];
                let spec = productGoodsSpecifications.map(i => i.value).join(' • ') || '';
                let ymsSpu = params.row.ymsSpu;
                let ymsSku = params.row.ymsSku;
                let name = params.row.name;
                let list = [
                  v.tableImg(h, params, null, imgPath),
                  h('div', {class: 'flex flex-direction ml10'}, [
                    h('div', [
                      h('Alink', {
                        props: {
                          linkName: v.handleMultilingualFields(name),
                          textAlign: 'left',
                          showDefaultTitle: true
                        },
                        on: {
                          AlinkBtn: () => {
                            v.toShopDetails(params.row.ymsProductId);
                          }
                        }
                      }),
                    ]),
                    h('p', {class: 'mt5'}, `${setConfigCharacters}SPU：` + ymsSpu),
                    h('p', {class: 'mt5'}, `${setConfigCharacters}SKU：` + ymsSku),
                    h('p', {class: 'mt5'}, [
                      h('span', aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000207')),
                      h('span', {class: 'color-green'}, spec)
                    ])
                  ])
                ];
                return h('div', {class: 'flex ptb10'}, list);
              }
            }
          },
          {
            title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000876'),
            key: 'myInventory',
            minWidth: 150,
            align: 'center',
            render(h, params) {
              let distributorInTransitStock = params.row.distributorInTransitStock || 0; // 在途库存
              let distributorAvailableNumber = params.row.distributorAvailableNumber || 0; // 可用库存
              return h('div', [
                h('p', aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000877') + distributorInTransitStock),
                h('p', aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000878') + distributorAvailableNumber),
              ])
            }
          },
          {
            title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000870'),
            key: 'supplierAvailableNumber',
            minWidth: 130,
            align: 'center',
            render(h, params) {
              let number = params.row.supplierAvailableNumber || 0;
              return h('span', number);
            }
          },
          {
            title: `${setConfigCharacters}${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000857')}`,
            key: 'ymsAvailableNumber',
            minWidth: 150,
            align: 'center',
            render(h, params) {
              let number = params.row.ymsAvailableNumber || 0;
              return h('span', number);
            }
          },
          {
            title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000879'),
            key: 'suggestPrice',
            minWidth: 130,
            align: 'center',
            render: (h, params) => {
              let suggestPrice = v.formatCurrency(params.row.suggestPrice);
              return h('div', [
                h('div', suggestPrice),
                h('div', '(' + v.currencyState + ')')
              ]);
            }
          },
          {
            title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000754'),
            key: 'weight',
            minWidth: 100,
            align: 'center'
          },
          {
            title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000880'),
            key: 'length',
            minWidth: 160,
            align: 'center',
            render: (h, params) => {
              return h('div', params.row.length + '*' + params.row.width + '*' + params.row.height);
            }
          },
          {
            title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000499'),
            key: 'status',
            minWidth: 110,
            align: 'center',
            render: (h, params) => {
              let status = params.row.status;
              let text = '';
              let statusArr = [
                {value: 0, name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000440')},
                {value: 1, name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000497')},
                {value: 2, name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000881')},
                {value: 3, name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000882')},
              ];
              statusArr.map((item) => {
                if (item.value === status) {
                  text = item.name;
                }
              });
              return h('span', text);
            }
          }
        ]
      }
      // 处理需要展示那些字段key
      if (v.systemType === 'distributor') {
        let data = defaultColumns.distributor;
        defaultColumns.distributor = data.filter((item) => {
          if (v.distributorShowFieldKeyList.includes(item.key)) {
            return item;
          }
        });
      }
      if (v.systemType === 'supplier') {
        let data = defaultColumns.supplier;
        defaultColumns.supplier = data.filter((item) => {
          if (v.supplierShowFieldKeyList.includes(item.key)) {
            return item;
          }
        });
      }
      v.tableColumns = defaultColumns[v.systemType];
    },
    // 初始化数据
    initFun() {
      let v = this;
      v.handlePageParams();
      v.handleColumns();
      if (v.showTree) {
        v.$refs['treeDom'].getClassificationData(v.showTree);
      }
      if (v.system === 'supplier') {
        v.search();
      }
      v.addCommonProductModal = true;
    },
    // 查询按钮
    search() {
      this.pageParams.pageNum = 1;
      this.pageParamsStatus = true;
    },
    // 重置按钮
    reset() {
      let v = this;
      if (v.$refs['pageParams']) {
        v.$refs['pageParams'].resetFields();
        v.pageParams.ymsProductCategoryIdList = [];
        v.pageParams.ymsProductIds = [];
      }
      v.inputTypeValue = 'textarea';
      if (v.matchingGoodsType === 'manual') {
        v.selectCommodityList = [];
      }
      if (['yms', 'supplier'].includes(v.systemType)) {
        v.changeTextareaInput(1);
      }
      if (['yms', 'distributor'].includes(v.systemType)) {
        v.changeInput(1);
        v.subscriptionBtn(0, 2);
      }
    },
    // 获取列表数据
    getList() {
      let v = this;
      v.tableLoading = true;
      v.total = 0;
      v.tableData = [];
      v.optionBtnWidth = [];
      let params = Object.assign({}, v.pageParams);
      if (v.systemType === 'yms') {
        handleData(['ymsSpus', 'supplierSpus']);
      }
      if (v.systemType === 'distributor') {
        handleData(['ymsSpus', 'ymsSkus']);
        params.distributorMerchantId = v.userInfo.merchantId;
      }
      if (v.systemType === 'supplier') {
        handleData(['spus', 'skus', 'names']);
      }

      function handleData(keyList) {
        for (let key of keyList) {
          params[key] = v.sepCommasFn(params[key], [',', '，', '\n']);
        }
      }

      v.axios.post(v.apiUrl, params).then(response => {
        v.tableLoading = false;
        if (response.data.code === 0) {
          let data = response.data.datas;
          let list = data.list || [];
          let keyList = ['supplierMerchantIdName', 'name'];
          let newList = v.handleMultilinguaTableFields(list, keyList);
          v.handleTableData(newList);
          v.total = data.total;
        }
      });
    },
    // 处理列表相关字段
    handleTableData(list) {
      let v = this;
      if (list && list.length > 0) {
        if (v.systemType === 'supplier') {
          if (v.selectTableData.length > 0) {
            list.map((ele) => {
              ele.quantity = 0;
              ele.isMaster = 0;
            });
          } else {
            list.map((item) => {
              item.quantity = 0;
              item.isMaster = 0;
            });
            /*list[0].isMaster = 1;*/
          }
          v.tableData = list;
          v.recordSelectGoods(v.tableData);
        } else if (v.systemType === 'distributor') {
          let ymsProductGoodsIds = [];
          list.map((item) => {
            ymsProductGoodsIds.push(item.ymsProductGoodsId);
          });
          if (ymsProductGoodsIds.length > 0) {
            (async () => {
              v.tableData = await v.getProductInfoByIds(ymsProductGoodsIds, list, false, false, false);
              v.recordSelectGoods(v.tableData);
            })();
          } else {
            v.tableData = list;
            v.recordSelectGoods(v.tableData);
          }
        } else {
          v.tableData = list;
          v.recordSelectGoods(v.tableData);
        }
      } else {
        v.tableData = [];
      }
    },
    // 选中分类查询
    selectCategory(data) {
      this.pageParams.ymsProductCategoryIdList = data[0] ? data[0].ymsProductCategoryId === -1 ? [] : [data[0].ymsProductCategoryId] : [];
      this.search();
    },
    // 取消选中的数据
    selectCancel(selection, row) {
      let v = this;
      let ymsProductId = row.ymsProductId;
      let productGoodsId = row.productGoodsId;
      let ymsProductGoodsId = row.ymsProductGoodsId;
      v.selectTableData.map((item, index) => {
        if (v.systemType === 'yms') {
          if (item.ymsProductId === ymsProductId) {
            v.selectTableData.splice(index, 1);
          }
        }
        if (v.systemType === 'supplier') {
          if (item.productGoodsId === productGoodsId) {
            v.selectTableData.splice(index, 1);
          }
        }
        if (v.systemType === 'distributor') {
          if (item.ymsProductGoodsId === ymsProductGoodsId) {
            v.selectTableData.splice(index, 1);
          }
        }
      })
    },
    // 全部取消选中的数据
    selectAllCancel(selection) {
      if (selection.length <= 0) {
        if (this.systemType === 'yms' || this.systemType === 'distributor') {
          let ymsProductIds = this.tableData.map((ele) => {
            return ele.ymsProductId;
          });
          if (ymsProductIds.length > 0) {
            this.selectTableData = this.selectTableData.filter((item) => {
              if (!ymsProductIds.includes(item.ymsProductId)) {
                return item;
              }
            });
          }
        }
        if (this.systemType === 'supplier') {
          let productGoodsIds = this.tableData.map((ele) => {
            return ele.productGoodsId;
          });
          if (productGoodsIds.length > 0) {
            this.selectTableData = this.selectTableData.filter((item) => {
              if (!productGoodsIds.includes(item.productGoodsId)) {
                return item;
              }
            });
          }
        }
      }
    },
    // 勾选列表数据
    tableChange(data) {
      this.ymsProductIdList = [];
      if (data.length > 0) {
        this.ymsProductIdList = data.map((i) => {
          if (this.showQuantity && !i.quantity) {
            i.quantity = 1;
          }
          return i;
        });
      }
    },
    // 跨页记录已勾选的商品
    recordSelectGoods(data) {
      let v = this;
      let list = [...v.selectTableData, ...v.ymsProductIdList];
      if (data && data.length > 0) {
        if (v.systemType === 'yms') {
          this.selectTableData = this.uniqueFunc(list, 'ymsProductId');
        } else if (v.systemType === 'supplier') {
          this.selectTableData = this.uniqueFunc(list, 'productGoodsId');
        } else if (v.systemType === 'distributor') {
          this.selectTableData = this.uniqueFunc(list, 'ymsProductGoodsId');
        }
        data.map((item, index) => {
          v.selectTableData.map((ele) => {
            if (v.systemType === 'yms') {
              if (item.ymsProductId === ele.ymsProductId) {
                v.$set(data[index], '_checked', true);
              }
            } else if (v.systemType === 'supplier') {
              if (item.productGoodsId === ele.productGoodsId) {
                v.$set(data[index], '_checked', true);
              }
            } else if (v.systemType === 'distributor') {
              if (item.ymsProductGoodsId === ele.ymsProductGoodsId) {
                v.$set(data[index], '_checked', true);
              }
            }
          })
        });
        v.updateTableDom('selection');
      }
    },
    // 选中商品的确定按钮
    addCommonProductBtn() {
      let v = this;
      v.reset();
      let talg = true;
      if (v.systemType === 'distributor') {
        if (v.selectTableData.length > 0) {
          v.selectTableData.map((item) => {
            if (!item.quantity) {
              item.quantity = 1;
            }
          });
        }
      }
      if (v.showSelectSku && v.$route.path === '/productDetails') {
        talg = v.selectTableData.every((item) => {
          return item.quantity > 0
        });
      }
      if (talg) {
        v.$emit('updateGoodsData', v.selectTableData);
        v.addCommonProductModal = false;
      } else {
        v.$Message.warning(aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000883'));
        return false;
      }
    },
    // 改变默认下拉选项的值(类型是 textarea)
    changeTextareaInput(value) {
      this.defaultTextareaValue = value;
      this.$refs.dropDownTextareaFilter.inputValue = '';
      this.dropDownTextareaList.map((item) => {
        if (Object.keys(this.pageParams).includes(item.parameterKey)) {
          this.pageParams[item.parameterKey] = '';
        }
      });
    },
    // 获取下拉选项筛选条件的值(类型是 textarea)
    updateTextareaValue(data) {
      this.pageParams[data.key] = data.value;
    },
    // 获取下拉选项筛选条件的值(类型是 text)
    updateInputValue(data) {
      this.pageParams[data.key] = data.value;
    },
    // 改变默认下拉选项的值(类型是 text)
    changeInput(value) {
      this.defaultSelectValue = value;
      if (this.systemType === 'distributor') {
        this.inputTypeValue = [1, 2].includes(value) ? 'textarea' : 'text';
      }
      this.$refs.dropDownFilter.inputValue = '';
      this.dropDownInputList.map((item) => {
        if (Object.keys(this.pageParams).includes(item.parameterKey)) {
          this.pageParams[item.parameterKey] = '';
        }
      });
    },
    // 监听添加商品弹窗
    addCommonProductChange(value) {
      if (!value) {
        this.removeStopScroll();
        this.reset();
        this.selectTableData = [];
        this.ymsProductIdList = [];
        this.tableData = [];
      } else {
        this.stopScroll();
      }
    },
    // 删掉已经选中的sku
    deleteTag(item, index) {
      let v = this;
      v.selectTableData.splice(index, 1);
      v.tableData.map((ele, idx) => {
        if (ele.productGoodsId === item.productGoodsId) {
          v.$refs['selection'].$refs.tbody.objData[idx]._isChecked = false;
        }
      });
    },
    // 选取收藏状态
    subscriptionBtn(index, value) {
      let v = this;
      v.pageParams.subscriptionStatus = value;
      v.subscriptionList.forEach((n, i) => {
        n.selected = i === index;
      });
    },
    // 保存已经选中的商品按钮
    queryProductBtn() {
      if (this.selectCommodityList.length > 0) {
        // 添加商品
        if (this.matchingGoodsType === 'add') {
          let query = {
            packageCode: this.packageCode,
            selectList: this.selectCommodityList
          };
          this.$emit('selectGoodsList', query);
          this.addCommonProductModal = false;
        }
      } else {
        this.$Message.warning(aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000884'));
        return false;
      }
    },
    // 选择商品
    selectCommodityBtn(data) {
      let v = this;
      if (data) {
        let equalTalg = false;
        let ymsProductGoodsId = data.ymsProductGoodsId;
        // 先判断当前已选的商品中是否包含当前选中的商品，如果包含则数量加1，不包含则将商品添加到已选商品数组
        if (v.selectCommodityList.length > 0) {
          v.selectCommodityList.map((item) => {
            if (item.ymsProductGoodsId === ymsProductGoodsId) {
              item.quantity += 1;
              equalTalg = true;
            }
          });
          if (!equalTalg) {
            data.quantity = 1;
            v.selectCommodityList.push(data);
          }
        } else {
          data.quantity = 1;
          v.selectCommodityList.push(data);
        }
        v.selectCommodityList = v.uniqueFunc(v.selectCommodityList, 'ymsProductGoodsId');
      }
    },
    // 移除已经选中的商品
    delCommodity(item, index) {
      this.selectCommodityList.splice(index, 1);
    },
    // 确认按钮
    selectConfirmationBtn() {
      let v = this;
      let list = [{
        platformId: v.platformOrderDetails.platformId,
        ymsPlatformAccountId: v.platformOrderDetails.ymsPlatformAccountId,
        ymsProductGoodsId: v.selectConfirmationObj.ymsProductGoodsId,
        platformItemSku: v.platformItemSku,
        ymsProductId: v.selectConfirmationObj.ymsProductId,
        ymsSku: v.selectConfirmationObj.ymsSku
      }];
      if (v.isSure === 1) {
        this.axios.post(api.post_ymsProductGoodsPlatformMapping_saveRelation, list, {loading: true, loadingText: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000213')}).then((res) => {
          if (res.data.code === 0) {
            let data = res.data.datas || [];
            if (data.length > 0) {
              v.$Message.error(aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000885'));
            } else {
              v.manualMatchingGoods(v.ymsProductGoodsId);
            }
            v.selectConfirmationModal = false;
            v.addCommonProductModal = false;
          }
        });
      } else {
        v.selectConfirmationModal = false;
        v.manualMatchingGoods(v.ymsProductGoodsId);
      }
    },
    // 手工匹配商品的确定按钮
    manualMatchingGoods(ymsProductGoodsId) {
      let v = this;
      let query = {
        ymsProductGoodsId: ymsProductGoodsId,
        ymsPlatformOrderTransactionId: v.ymsPlatformOrderTransactionId
      };
      v.axios.post(api.post_ymsPlatformOrder_matchProductGoods, query, {loading: true, loadingText: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000213')}).then(response => {
        if (response.data.code === 0) {
          v.$Message.success(aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000085'));
        }
        v.addCommonProductModal = false;
        v.$emit('updateData', true);
      });
    },
    // 设为主料
    setAsMainMaterialChange(val, index) {
      let v = this;
      v.selectTableData.map((ele, idx) => {
        ele.isMaster = 0;
        if (idx === index) {
          ele.isMaster = 1;
        }
      });
      v.$forceUpdate();
    },
    // 监听确认弹窗
    selectConfirmationChange(value) {
      if (!value) {
        this.isSure = 0;
        this.selectConfirmationObj = {};
        this.platformItemSku =null;
      }
    }
  },
  watch: {
    pageParamsStatus(value) {
      let v = this;
      if (v.ymsNoTable) {
        v.pageParamsStatus = false;
        return;
      }
      if (value) {
        v.getList();
        v.pageParamsStatus = false;
      }
    },
    // 监听选中的数据
    ymsProductIdList: {
      handler(data) {
        let v = this;
        if (data.length > 0) {
          let list = [...v.selectTableData, ...data];
          if (v.systemType === 'yms') {
            v.selectTableData = v.uniqueFunc(list, 'ymsProductId');
          } else if (v.systemType === 'supplier') {
            v.selectTableData = v.uniqueFunc(list, 'productGoodsId');
          } else if (v.systemType === 'distributor') {
            v.selectTableData = v.uniqueFunc(list, 'ymsProductGoodsId');
          }
          v.$forceUpdate();
        }
      },
      immediate: true,
      deep: true
    }
  },
  components: {
    commonTree,
    dropDownFilter
  }
}
</script>

<style lang="less" scoped>
.details_box_styles {
  min-height: 624px;
  box-sizing: border-box;

  .commodity_box {
    display: flex;
    justify-content: space-between;

    .content_box {
      width: 78%;

      /deep/ .ivu-card-body {
        padding: 0 !important;
      }
    }

    .content_content {
      width: 100%;

      /deep/ .ivu-card-body {
        padding: 0 !important;
      }
    }
  }


  .tag_box_styles {
    flex: 1;
    padding: 0 8px;
    max-height: 150px;
    overflow-y: auto;
    box-sizing: border-box;

    /deep/ .ivu-icon-ios-close {
      color: #2D8CF0 !important;
    }

    .tag_box_item {
      margin: 0 10px 10px 0;
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      .ipt {
        width: 75px;
        margin: 0 5px 3px 5px;
      }
    }
  }

  .options_box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    .select_title {
      color: #333;
      font-size: 14px;
      font-weight: bold;
    }

    .btns_box {
      display: flex;
      align-items: center;
    }
  }

  .tag_box_conter {
    display: flex;
    flex-wrap: wrap;
    color: #fff;
    max-height: 200px;
    overflow-y: auto;

    .tag_item_style {
      display: flex;
      align-items: center;
      height: 38px;
      line-height: 38px;
      margin: 5px !important;

      /deep/ .ivu-tag-text {
        display: flex;
        align-items: center;
      }

      .text {
        font-size: 14px;
      }

      .txt {
        margin: 0 4px;
      }
    }

    .tag_bg_distributor {
      background-color: #FD5425 !important;
    }

    .tag_bg_default {
      background-color: #2D8CF0 !important;
    }
  }

  /deep/ .ivu-tooltip-rel {
    width: initial !important;
  }
}
</style>
