
import { aliasb039bd6e4486415fba0a6b42f8cd95d1 } from '@/customFolder/customVueAlias.js';
// 公共方法
export default {
  data() {
    return {
      productTypeList: [
        {name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000143'), value: "01", disabled: true},
        {name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000144'), value: "02", disabled: false},
        {name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000145'), value: "03", disabled: false},
        {name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000146'), value: "04", disabled: false},
        {name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000147'), value: "05", disabled: false},
        {name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000148'), value: "06", disabled: false},
        {name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000149'), value: "07", disabled: false},
        {name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000150'), value: "08", disabled: false},
        {name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000151'), value: "09", disabled: false},
        {name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000152'), value: "10", disabled: false}
      ],
      deliveryIntervalList: [
        {value: 1, name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000153')},
        {value: 2, name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000154')},
        {value: 3, name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000155')},
        {value: 4, name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000156')},
        {value: 5, name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000157')},
        {value: 6, name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000158')},
        {value: 7, name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000159')}
      ], // 发货时效的数据
      productTypeListValue: ["01"]
    };
  },
  methods: {
    checkIdenticalDon(h, column, title, data, key, showCheckbox = false, extendConfig) {
      let v = this;
      let {required, tipContent} = extendConfig || {};
      if (column.checkIdentical === undefined) {
        column.checkIdentical = false;
      }
      return h(
        "div",
        {
          style: {
            display: "flex",
            lineHeight: "22px"
          }
        },
        [
          h(
            "p",
            {
              class: required ? "required" : ""
            },
            title
          ),
          showCheckbox ||
          h(
            "Checkbox",
            {
              style: {
                marginLeft: "12px",
                display: (() => {
                  return v.isDisabled ? "none" : "inline-block";
                })()
              },
              props: {
                value: column.checkIdentical
              },
              on: {
                "on-change": (value) => {
                  if (value) {
                    // 获取列表中有值的对象
                    let newList = JSON.parse(JSON.stringify(data));
                    let list = newList.filter((item) => {
                      if (v.typeOf(key) === "array") {
                        return item[key[0]];
                      } else {
                        return item[key];
                      }
                    });
                    data.map((i, index) => {
                      if (v.typeOf(key) === "array") {
                        key.forEach((k) => {
                          let val = list.length > 0 ? list[0][k] : data[0][k];
                          v.$set(data[index], k, val);
                        });
                      } else {
                        // 存在相同的部位才能添加相同的数据
                        if (key === 'partAndMeatSetting') {
                          let partNameList = [];
                          let obj = list.length > 0 ? list[0] : data[0];
                          if (obj) {
                            let productGoodsExtendList = obj.ingredientsObj.productGoodsExtendList || [];
                            let newPartAndMeatSetting = obj.partAndMeatSetting;
                            partNameList = productGoodsExtendList.map((ele) => {
                              return ele.partName;
                            });
                            let newList = i.ingredientsObj.productGoodsExtendList;
                            newList.map((ele) => {
                              if (partNameList.includes(ele.partName)) {
                                v.$set(data[index], key, newPartAndMeatSetting);
                              }
                            });
                          }
                        } else {
                          let val = list.length > 0 ? list[0][key] : data[0][key];
                          v.$set(data[index], key, val);
                        }
                      }
                    });
                    v.$forceUpdate();
                  }
                }
              }
            },
            aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000160')
          ),
          showCheckbox ||
          h(
            "Tooltip",
            {
              props: {
                transfer: true,
                maxWidth: 250,
                content: tipContent
              },
              style: {
                marginLeft: "5px"
              }
            },
            [
              h("Icon", {
                props: {
                  type: "md-help-circle",
                  size: 22
                },
                style: {
                  cursor: "pointer",
                  color: "#2D8CF0"
                }
              })
            ]
          )
        ]
      );
    },
    validateSku(value) {
      // 非表单校验 [0-9#&\+\-\.\/A-Z\[\]\^\_a-z\}\{\~ ]
      let spuRegExp = this.$regular.spuRegExp;
      if (spuRegExp.test(value)) {
        return true;
      } else {
        return false;
      }
    },
    validatePropertyName(currentSkuProperty) {
      // 验证属性名是否有重复的
      currentSkuProperty.invalid = false;
      let newName = currentSkuProperty.name;
      this.skuPropertyList.forEach((skuProperty, i) => {
        if (
          currentSkuProperty !== skuProperty &&
          newName === skuProperty.name
        ) {
          currentSkuProperty.invalid = true;
        }
      });
    },
    delSkuPropertyValue(currentSkuProperty, currentPropertyValue, index) {
      // 删除属性值
      let self = this;
      if (self.isDisabled) {
        return;
      }

      // 判断当前规格的有效属性值个数(不包含当前属性值),和当前属性值是否存在有效的sku
      let validOtherPropertyValueCount = 0;
      let currentPropertyValueIsValid = false;

      self.productInfo.productGoodsList.forEach((productGoods, i) => {
        productGoods.productGoodsSpecifications.forEach(
          (productGoodsSpecification, i) => {
            if (
              currentSkuProperty.values.indexOf(
                productGoodsSpecification.value
              ) !== -1 &&
              productGoodsSpecification.value !== currentPropertyValue &&
              productGoodsSpecification.name === currentSkuProperty.name
            ) {
              validOtherPropertyValueCount += i + 1;
            }
            if (
              productGoodsSpecification.name === currentSkuProperty.name &&
              productGoodsSpecification.value === currentPropertyValue
            ) {
              currentPropertyValueIsValid = true;
            }
          }
        );
      });

      currentSkuProperty.values.splice(index, 1);
      // if (currentSkuProperty.values.length === 0) {
      //   self.tagInputValueChange(index);
      // }
      // 如果当前的值是无效的属性直接返回
      if (!currentPropertyValueIsValid) {
        return;
      }
      // 筛选有效的sku
      let filterResult = self.productInfo.productGoodsList.filter(function (
        productGoods,
        i
      ) {
        let productGoodsSpecificationIndex = -1; // 当前属性值的下标
        productGoods.productGoodsSpecifications.forEach(
          (productGoodsSpecification, i) => {
            if (
              productGoodsSpecification.name === currentSkuProperty.name &&
              productGoodsSpecification.value === currentPropertyValue
            ) {
              productGoodsSpecificationIndex = i;
            }
          }
        );
        if (productGoodsSpecificationIndex === -1) {
          return true;
        } else if (validOtherPropertyValueCount > 0) {
          return false;
        }
        productGoods.sku = "";
        productGoods.productGoodsSpecifications.splice(
          productGoodsSpecificationIndex,
          1
        );
        if (productGoods.productGoodsSpecifications.length === 0) {
          return false;
        }

        return true;
      });

      if (filterResult.length !== 0) {
        self.productInfo.productGoodsList = self.deepCopy(filterResult);
      } else if (self.skuData.length !== 0) {
        // 如果筛选出有效的sku数量等于0,那么把最后一个货品做为商品的值
        self.productInfo.productGoodsList = [self.deepCopy(self.skuData[0])];
        self.productInfo.productGoodsList[0].productGoodsSpecifications = [];
      }
      self.skuData = filterResult;
      self.reloadSkuColumn();
    },
    tagInputValueChange(index) {
      // 标签输入框值变动重新计算宽度
      let tagInputDom = document.getElementById("tagInput" + index);
      let customTagInputDom = document.getElementById("customTagInput");
      if (customTagInputDom.clientWidth - tagInputDom.offsetLeft < 50) {
        tagInputDom.style.width = customTagInputDom.clientWidth + "px";
      } else {
        tagInputDom.style.width =
          customTagInputDom.clientWidth - tagInputDom.offsetLeft + "px";
      }
    },

    // 新增颜色属性
    addColorAttribute(skuProperty, value, index) {
      let v = this;
      (function (sp, spv, i) {
        v.$nextTick(function () {
          v.reloadSkuData(sp, spv, i);
          v.reloadSkuColumn();
        });
      })(skuProperty, value, index);
    },

    reloadSkuData(currentSkuProperty, currentPropertyValue, index) {
      // 重新渲染表格
      let self = this;
      // 如果这个是新增的属性,并且存在SKU那么把之前的sku都加上这个属性
      if (currentSkuProperty.values.length === 1 || self.skuData.length === 0) {
        self.productInfo.productGoodsList.forEach((newSku, i) => {
          let leftPropertyArr = newSku.productGoodsSpecifications.slice(
            0,
            index
          );
          let rightPropertyArr = newSku.productGoodsSpecifications.slice(index);
          leftPropertyArr.push({
            name: currentSkuProperty.name,
            value: currentPropertyValue
          });
          newSku.productGoodsSpecifications =
            leftPropertyArr.concat(rightPropertyArr);
        });
        if (self.productInfo.productGoodsList[0].sku !== null) {
          self.productInfo.productGoodsList[0].sku =
            self.productInfo.productGoodsList[0].sku;
        }
        self.productInfo.productGoodsList = self.sortByColor(
          self.productInfo.productGoodsList
        );
        self.skuData = self.deepCopy(self.productInfo.productGoodsList);
        return;
      }
      let newSkuData = [];
      let copyCurrentSkuPropertyValues = self.deepCopy(
        currentSkuProperty.values
      );
      currentSkuProperty.values = [currentPropertyValue];
      for (let i = 0; i < self.skuPropertyList.length; i++) {
        let skuProperty = self.skuPropertyList[i];
        if (skuProperty.values.length !== 0) {
          skuProperty.values.forEach((value, i1) => {
            let newSku = {};
            if (i1 === 0 && self.productInfo.productGoodsList.length === 0) {
              newSku = self.deepCopy(self.productInfo.productGoodsList[0]);
              newSku.sku = self.productInfo.spu;
            } else {
              newSku = self.deepCopy(
                self.productInfoPrototype.productGoodsList[0]
              );
            }
            newSku.productGoodsSpecifications = [
              {
                name: skuProperty.name,
                value: value
              }
            ];
            newSkuData.push(newSku);
          });
          self.skuPropertyCartesian(
            newSkuData,
            self.skuPropertyList[i + 1],
            i + 1
          );
          self.productInfo.productGoodsList =
            self.productInfo.productGoodsList.concat(newSkuData);
          self.skuData = self.deepCopy(self.productInfo.productGoodsList);
          currentSkuProperty.values = copyCurrentSkuPropertyValues;
          break;
        }
      }
    },
    skuPropertyCartesian(newSkuData, nextSkuProperty, index) {
      // 计算sku属性笛卡尔(两个数组乘积后和下个数组乘积)
      let self = this;
      if (index < self.skuPropertyList.length) {
        let tempSkuData = [];
        newSkuData.forEach((newSku, i1) => {
          if (nextSkuProperty.values.length > 0) {
            let copyNewSku = self.deepCopy(newSku);
            nextSkuProperty.values.forEach((value, i2) => {
              let tempSku = null;
              if (i2 === 0) {
                tempSku = newSku;
              } else {
                tempSku = self.deepCopy(copyNewSku);
                tempSkuData.push(tempSku);
              }
              tempSku.productGoodsSpecifications.push({
                name: nextSkuProperty.name,
                value: value
              });
            });
          }
        });
        tempSkuData.forEach((tempSku, i1) => {
          newSkuData.push(tempSku);
        });
        this.skuPropertyCartesian(
          newSkuData,
          this.skuPropertyList[index + 1],
          index + 1
        );
      }
    },
    addSkuProperty() {
      // 新增SKU属性
      this.skuPropertyList.push({
        invalid: false,
        name: "",
        selected: true,
        values: []
      });
    },
    trim(string) {
      if (string) {
        string = string.replace(/(^\s*)|(\s*$)/g, "");
      }
      return string;
    },
    delSkuProperty(currentSkuProperty, index) {
      // 删除SKU属性
      let self = this;
      for (let i = currentSkuProperty.values.length - 1; i >= 0; i--) {
        let value = currentSkuProperty.values[i];
        self.delSkuPropertyValue(currentSkuProperty, value, i);
      }
      self.skuPropertyList.splice(index, 1);
    },
    reloadSkuColumn() {
      // 重新生成表头
      let self = this;
      if (self.skuData.length > 0) {
        let leftSkuColumns = self.skuColumns.slice(0, 1); // 属性左边列定义
        let centerSkuColumns = []; // 属性列定义
        let rightSkuColumns = []; // 属性右边列定义
        let changed = false; // 属性列定义是否有变更
        for (let i = 0; i < self.skuColumns.length; i++) {
          if (self.skuColumns[i].key === "sku") {
            rightSkuColumns = self.skuColumns.slice(i, self.skuColumns.length);
            let productGoodsSpecifications =
              self.skuData[0].productGoodsSpecifications;
            if (
              productGoodsSpecifications &&
              productGoodsSpecifications.length !== self.skuColumns.slice(1, i)
            ) {
              changed = true;
            }
            break;
          }
        }
        if (changed) {
          self.productInfo.productGoodsList = self.sortByColor(
            self.productInfo.productGoodsList
          );
          for (
            let i =
              self.productInfo.productGoodsList[0].productGoodsSpecifications
                .length - 1;
            i >= 0;
            i--
          ) {
            let productGoodsSpecification =
              self.productInfo.productGoodsList[0].productGoodsSpecifications[
                i
                ];

            rightSkuColumns.unshift({
              title: productGoodsSpecification.name,
              key: "multiAttribute" + i,
              width: 110,
              align: "center",
              renderHeader: (h, params) => {
                return h(
                  "div",
                  {
                    class: "required"
                  },
                  params.column.title
                );
              },
              render: (h, params) => {
                let productGoodsSpecifications =
                  self.productInfo.productGoodsList[params.index]
                    .productGoodsSpecifications;
                // let productGoodsSpecifications = self.sortByColor(self.productInfo.productGoodsList, params.index);
                let value = productGoodsSpecifications
                  ? productGoodsSpecifications[i].value
                  : "";
                return h("span", value);
              }
            });
          }
          // 如果供应商商户，sku列表要屏蔽一些字段
          if (self.isSupplierTalg) {
            let data = centerSkuColumns.concat(rightSkuColumns);
            let colums = [];
            // 屏蔽的字段对应的key值
            let arr = [
              "upc",
              "ean",
              "isbn",
              "tagName",
              "status",
              "assemble",
              "productType",
              "img"
            ];
            if (data) {
              data.map((item, index) => {
                if (!arr.includes(item.key)) {
                  colums.push(item);
                }
              });
              if (self.operationType === "look") {
                let item = {
                  title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000161'),
                  key: "coloImg",
                  width: 120,
                  align: "center",
                  render(h, params) {
                    return self.tableImg(h, params, "coloImg");
                  }
                };
                colums.unshift(item);
              }

              self.skuColumns = self.uniqueFunc(colums, "key");
              this.pw(this.skuColumns.length - 4, "skuColumns");
            }
          } else {
            this.skuColumns = leftSkuColumns
              .concat(centerSkuColumns)
              .concat(rightSkuColumns);
            this.pw(this.skuColumns.length - 4, "skuColumns");
          }
        }
      }
    },
    pw(num, objName) {
      let v = this;
      if (num !== undefined) {
        let minWidth = v.minDomWidth;
        if (minWidth === undefined) minWidth = 1280;
        if (typeof num === "number") {
          if (objName) {
            v[objName][num].width =
              v.tableWidth <= minWidth ? v.minWidth : null;
          } else {
            v.orderColumn[num].width =
              v.tableWidth <= minWidth ? v.minWidth : null;
          }
        } else if (typeof num === "object") {
          num.forEach((n, i) => {
            v.orderColumn[n].width =
              v.tableWidth <= minWidth ? v.minWidth[i] : null;
          });
        }
      }
    }
  },
  mounted() {
  }
};
